<template>
  <div class="home">
    <v-row class="text-center">
      <v-col>
        <h1 class="display-2 font-weight-bold mb-3">Admin</h1>
      </v-col>
    </v-row>
    <v-list>
      <v-list-item-group active-class="green--text">
        <template v-for="(item, index) in allDates">
          <v-list-item :key="item.id">
            <template>
              <v-list-item-content>
                <v-list-item-title class="text--primary">
                  <router-link
                    style="text-decoration: none; color: #333"
                    :to="{ name: 'Walk', params: { id: item.id } }"
                  >
                    <v-icon color="green"> mdi-emoticon-cool-outline </v-icon>
                    {{ item.datum }}
                  </router-link>
                </v-list-item-title>
                <router-link
                  class="ml-7"
                  :to="{ name: 'Walk', params: { id: item.id } }"
                >
                  {{ item.naam }}
                </router-link>
              </v-list-item-content>

              <v-list-item-action>
                <v-icon color="red" @click="deleteDate(item)">
                  mdi-delete
                </v-icon>
                <!-- <v-list-item-action-text >Verwijderen</v-list-item-action-text> -->
              </v-list-item-action>
            </template>
          </v-list-item>

          <v-divider
            v-if="index < allDates.length - 1"
            :key="index"
          ></v-divider>
        </template>
      </v-list-item-group>
    </v-list>
  </div>
</template>
<script>
import { notesRef } from "../../store/firestore";
import { mapGetters } from "vuex";

export default {
  name: "Admin",
  components: {},
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      user: "user",
    }),
  },
  data: () => ({
    allDates: [],
  }),
  created() {
    this.getData();
  },
  methods: {
    getData() {
      var that = this;
      var alledagen = [];
      that.allDates = [];
      // haal alle events op in de toekomst
      notesRef
        .get()
        .then(function (querySnapshot) {
          (that.arrayEvents = ["2020-01-01"]),
            querySnapshot.forEach(function (doc) {
              // doc.data() is never undefined for query doc snapshots
              var test = doc.data();
              test.id = doc.id;
              alledagen.push(test);
            });
          alledagen.sort(function (a, b) {
            return Date.parse(a.datum) - Date.parse(b.datum);
          });
          that.allDates = alledagen;
        })
        .catch(function (error) {
          console.log("Error getting documents: ", error);
        });
    },
  },
};
</script>